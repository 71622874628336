<template>
    <div>
        <!--form add & update adverts-->
        <ek-dialog
            ref="dialog"
            @ok="submitForm"
            @close="resetForm"
            :isEdit="!!advertDto.id"
            :title="advertDto.id ? 'تعديل اعلان' : 'اضافة اعلان'"
            @delete="deleteAd(advertDto.id)"
            @search="setSearch"
            btnText="اعلان جديد"
            placeholder="ابحث عن اعلان"
        >
            <template #body>
                <div>
                    <validationObserver ref="classForm">
                        <b-form>
                            <ek-input-text
                                v-model="advertDto.title"
                                name=" عنوان الإعلان"
                                label=" عنوان الإعلان"
                                :rules="[
                                    {
                                        type: 'required',
                                        message: ' عنوان الإعلان مطلوب',
                                    },
                                ]"
                                placeholder="عنوان الإعلان مطلوب"
                            ></ek-input-text>

                            <ek-input-text
                                name="  ترتيب الأعلان"
                                label="  ترتيب الإعلان"
                                ref="order"
                                v-model="advertDto.priority"
                                placeholder=" ادخل ترتيب الإعلان "
                                :rules="[
                                    {
                                        type: 'required',
                                        message: 'اضافة ترتيب الإعلان مطلوب',
                                    },
                                ]"
                            ></ek-input-text>

                            <ek-date-picker
                                :rules="[
                                    {
                                        type: 'required',
                                        message: 'يرجى ادخال بداية الإعلان ',
                                    },
                                ]"
                                name="date"
                                placeholder="ادخل بداية الاعلان"
                                label=" بداية الإعلان"
                                v-model="advertDto.startDate"
                            ></ek-date-picker>

                            <ek-date-picker
                                :rules="[
                                    {
                                        type: 'required',
                                        message: 'يرجى ادخال نهاية الإعلان ',
                                    },
                                ]"
                                name="dataa"
                                placeholder="ادخل نهاية الاعلان"
                                label=" نهاية الإعلان"
                                v-model="advertDto.endDate"
                            ></ek-date-picker>

                            <label>صورة الإعلان</label>
                            <ek-input-image
                                :image="
                                    advertDto.url
                                        ? $store.getters['app/domainHost'] +
                                          '/' +
                                          advertDto.url
                                        : ''
                                "
                                name="img"
                                ref="imgFile"
                                @input="uploadAdFile($event)"
                            >
                                <h5>اسحب الملف او انقر للتحميل</h5>
                            </ek-input-image>
                        </b-form>
                    </validationObserver>
                </div>
            </template>
        </ek-dialog>
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";

export default {
    computed: {
        ...mapState({
            advertDto: (state) => state.adverts.advertDto,
            isDialogOpen: (state) => state.adverts.isDialogOpen,
            url: (state) => state.subjects.url,
        }),
        ...mapGetters(["getUrl"]),
    },
    methods: {
        submitForm() {
            this.$refs.classForm.validate().then((suc) => {
                if (suc) {
                    if (this.advertDto.id) {
                        this.updateAds(this.advertDto);
                        this.$refs.dialog.close();
                    } else {
                        this.addAds({ ...this.advertDto, url: this.url })
                            .then(() => {
                                this.$refs.dialog.close();
                            })
                            .catch(() => {});
                    }
                }
            });
        },
        updateAdsDate($event) {
            this.Update_Ads_Dto_Date($event);
        },
        uploadAdFile(event) {
            if (event && event.file) {
                this.addFile({ file: event.file, folderName: "Ad" }).then(
                    (res) => {
                        this.advertDto.url = res.data;
                    }
                );
            }
        },
        deleteAd(id) {
            this.deleteAd(id);
            this.$refs.dialog.close();
        },
        resetForm() {
            this.$refs.classForm.reset();
            this.$refs.imgFile.reset();
            this.$store.commit("SET_ADS_DTO");
            this.$store.commit("IS_DIALOG_OPEN", false);
        },
        setSearch(query) {
            this.$store.commit("Set_Search_Dto", {
                keys: ["title", "startDate", "endDate"],
                query,
            });
        },
        ...mapActions(["addAds", "updateAds", "deleteAd", "addFile"]),
        ...mapMutations(["Update_Ads_Dto_Date"]),
    },
    watch: {
        isDialogOpen(nv) {
            if (nv) {
                this.$refs.dialog.open();
            }
        },
    },
};
</script>
